.main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.topFooter {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    
}

.socials {
    width:250px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;   
    
}

.socials h5 {
    font-size: 12px;
    text-align: center;
    padding: 5px;
    margin: auto;
    
}

.socialIcons{
    margin:auto;
}

a {
    color: rgb(140,135,136);
    text-decoration: none;   
}


@media  screen and (max-width: 796px) {
    .media{
        font-size: 12px;
    }

    .topFooter {
        display: block;
    }

    .socials {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        text-align: center;
        
    }

    .socialIcons {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }
}