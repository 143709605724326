.main {
    background-color: white;
    margin: 3rem 0;
}

.sectionContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.left {
    flex: 1;
    width: 40%;

    margin: 0 auto;
    text-align: left;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contact h2 {
  padding: .5rem;
}

.right {
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;

}

.colorfulForm {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
  }
  
  .formGroup {
    margin-bottom: 20px;
  }
  
  .formLabel {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .formInput {
    width: 100%;
    padding: 10px;
    border: none;
    background-color: #fff;
    color: #333;
    border-radius: 5px;
  }
  
  textarea.formInput {
    height: 100px;
  }
  
  .formButton {

    display: block;
    width: 100%;
    padding: 10px;
    background-color: #212529;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .formButton:hover {
    background-color: #34383d;
    color: #fff;
  }
  
  .invalid {
    border: 2px solid red;
    border-radius: .5rem;
  }

  .errorText {
    color: #b40e0e;
  }

  .submit {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: white;
    width: 500px;
    height: 250px;
    border: 1px solid transparent;
    border-radius: 20px;
  }
  

@media only screen and (max-width: 768px) {
    .sectionContainer {
        flex-direction: column;
    }

    .left {
        width: 100%;
    }

    .center {
    width: 100%;
    display: block;
    height: 1px;
    border-top: 6px solid rgb(33,37,41);
    margin: 1em 0;
    }

    .right {
        width: 100%;
    }
}