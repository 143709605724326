.notificationsContainer {
    width: 320px;
    height: auto;
    font-size: 0.875rem;
    line-height: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .flex {
    display: flex;

  }
  
  .flexShrink0 {
    flex-shrink: 0;
  }
  
  .success {
    padding: 1rem;
    border-radius: 0.375rem;
    background-color: rgb(240 253 244);
  }
  
  .succesSvg {
    color: rgb(74 222 128);
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .successPromptWrap {
    margin-left: 0.75rem;
  }
  
  .successPromptHeading {
    font-weight: bold;
    color: rgb(22 101 52);
  }
  
  .successPromptPrompt {
    margin-top: 0.5rem;
    color: rgb(21 128 61);
  }
  
  .successButtonContainer {
    display: flex;
    margin-top: 0.875rem;
    margin-bottom: -0.375rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  
  .successButtonSecondary {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: auto;
    background-color: #ECFDF5;
    color: #065F46;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 0.375rem;
    border: none;
  }
  