.main {
    padding-bottom: 1.2rem;

}

.header {
    margin: 0 0 5px 0;
    color: #333333;
    font-size: 35px;
    line-height: 38px;
    font-weight: 700;
    padding: 65px 0 0 0;
    text-align: center;
}

.paragraph {
    margin-bottom: 0;
    line-height: 26px;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    word-wrap: break-word;
}

a {
  text-decoration: none !important;
}

.btn {
  font-size: 18px;
  color: rgb(33,37,41);
  font-family: inherit;
  font-weight: 800;
  cursor: pointer;
  position: relative;
  border: none;
  background: none;
  text-transform: uppercase;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: color;
}

.btn:focus,
.btn:hover {
  color: rgb(84,84,84)
}

.btn:focus:after,
.btn:hover:after {
  width: 100%;
  left: 0%;
}

.btn:after {
  content: "";
  pointer-events: none;
  bottom: -2px;
  left: 50%;
  position: absolute;
  width: 0%;
  height: 2px;
  background-color: rgb(84,84,84);
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 400ms;
  transition-property: width, left;
}


