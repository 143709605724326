.main {
 height: 100vh;
 background-image: url('../../../assets/images/constructionSite2.jpg');
background-position: center;
background-repeat: no-repeat;
display: flex;
justify-content: center;
align-items: center;

}

.main img {
    margin-left: -5%;
    width: 100%;
    height: auto;

}

.overlay {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.7);
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}