.notificationsContainer {
    width: 320px;
    height: auto;
    font-size: 0.875rem;
    line-height: 1.25rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .flex {
    display: flex;
  }
  
  .flexShrink0 {
    flex-shrink: 0;
  }
  
  .errorAlert {
    border-radius: 0.375rem;
    padding: 1rem;
    background-color: rgb(254 242 242);
  }
  
  .errorSvg {
    color: #F87171;
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .errorPromptHeading {
    color: #991B1B;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: bold;
  }
  
  .errorPromptContainer {
    display: flex;
    flex-direction: column;
    margin-left: 1.25rem;
  }
  
  .errorPromptWrap {
    margin-top: 0.5rem;
    color: #B91C1C;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  
  .errorPromptPrompt {
    /* margin-top: 0.5rem; */
    color: #B91C1C;
    text-align: left;
    padding: 0;
  }

  .errorButtonContainer {
    display: flex;
    margin-top: 0.875rem;
    margin-bottom: -0.375rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
  }
  
  .errorButtonSecondary {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    margin-left: auto;
    background-color: transparent;
    color: #B91C1C;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-radius: 0.375rem;
    border: none;
  }