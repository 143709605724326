.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.sectionHeight {
  height: 400px;
}

.swiper {
  width: 100%;
  height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2rem;
}

.imageCarousel {
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
}

.carouselImage {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.footerButton { 
  color: black;
}

@media screen and (max-width: 768px) {

  .sectionHeight {
    height: auto;
  }

  .swiper {
    height: auto; 
    display: block;
    text-align: center;
  }

  .imageCarousel {
    max-width: 100%;
    max-height: 60vh; 
    display: inline-block;
  }
  
  .carouselImage {
    height: auto;
    width: 100%;
    display: block;
    margin: 0 auto;
  }
}