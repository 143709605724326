.main {
    background-color: #B4B4B4;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex-wrap: 1;
}

.header {
    margin-bottom: 1.2rem;
    padding-top: 2rem;
    color: #333333;
    font-size: 35px;
    line-height: 38px;
    font-weight: 700;
    text-align: center;
}

.paragraph {
    text-align: left;
    hyphens: auto;
    line-height: 26px;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    word-wrap: break-word;
    padding-bottom: 2rem;
    padding-left: 1.2rem; 
    padding-right: 1.2rem; 
}

@media only screen and (max-width:768px) {
    .paragraph {
    }
  }