.main {
    background-color: #B4B4B4;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2rem;
}

.header h2 {

    color: #333333;
    font-size: 35px;
    line-height: 38px;
    font-weight: 700;
    text-align: center;
}

.description {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    line-height: 26px;
    font-size: 16px;
    color: #333333;
    font-weight: 400;
    padding-bottom: 1.2rem;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    padding: 3rem;
}

.list li {
    text-align: left;
}

@media only screen and (max-width:768px) {
    .description {
      display: block;
    }
    .list {
        padding: .5rem;
        margin-left: 20%;
    }
  }
